import { DoubleSide, FrontSide, PlaneGeometry, ShaderMaterial, Vector4 } from "three";

export const REDEYE_FRAGMENT = `
    uniform vec4 resolution;
uniform float x;
uniform float y;
uniform float alpha;
uniform float blur;
uniform float time;
uniform float radius;
uniform float power;
uniform float timeLight;
varying vec2 vUv;
varying vec3 vPosition;

// Función para interpolación cúbica
float fade(float t) {
    return t * t * t * (t * (t * 6.0 - 15.0) + 10.0);
}

// Función para generar un número aleatorio basado en una semilla
float random(vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
}

// Función para generar ruido Perlin 2D
float perlinNoise(vec2 p) {
    vec2 i = floor(p);
    vec2 f = fract(p);

    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));

    vec2 u = vec2(fade(f.x), fade(f.y)); // Aplicar fade a cada componente

    return mix(mix(a, b, u.x), mix(c, d, u.x), u.y);
}

// Función para generar ruido fractal menos regular
float fractalNoise(vec2 p) {
    float total = 0.0;
    float amplitude = 0.5;
    float frequency = 1.0;
    const int octaves = 4;

    for (int i = 0; i < octaves; i++) {
        total += perlinNoise(p * frequency) * amplitude;
        frequency *= 2.0;
        amplitude *= 0.5;
    }

    return total;
}

// Función para generar un tamaño de desenfoque variable
float getBlurSize(float time) {
    float noiseValue = fractalNoise(vec2(time * 0.1, time * 0.1));
    return blur + 0.005 * noiseValue;
}

void main() {
    // Calcular la relación de aspecto de la pantalla
    float aspectRatio = resolution.x / resolution.y;

    // Centro de la pantalla ajustado por la relación de aspecto
    vec2 center = vec2(x, y);

    // Ajustar el radio basado en la relación de aspecto
    vec2 scaledUv = (vUv - center) * vec2(aspectRatio, 1.0) + center;
    float dist = distance(scaledUv, center);

    // Crear un círculo rojo con vibración en intensidad usando ruido fractal
    // float noiseFactor = fractalNoise(vec2(timeLight * 0.1, timeLight * 0.1)) * 0.5; // Factor de ruido añadido
    // float intensityVibration = 1.0 + noiseFactor; // Vibración de intensidad basada en ruido fractal
    vec4 color1 = vec4(1.0, 0.0, 0.0, 1.0) * 1.5;

    // Crear un círculo rojo con vibración de brillo
    float brightnessFactor = 1.0 - smoothstep(0.0, radius, dist * .8); // Brillo aumenta cerca del centro
    brightnessFactor += fractalNoise(vec2(timeLight * 0.1, timeLight * 0.1)) * (1.0 * power); // Añadir vibración con ruido fractal
    vec4 color2 = vec4(brightnessFactor, brightnessFactor * .7, brightnessFactor * .4, 1.0); 

    // Mezclar color del círculo con el fondo negro
    vec4 color = mix(color1, color2, 0.4);

    // Tamaño de desenfoque variable
    float edgeThickness = getBlurSize(timeLight); // Variable entre 0.10 y 0.20
    float alphaSmooth = smoothstep(radius, radius - edgeThickness, dist);

    // Mezclar color del círculo con el fondo negro
    vec4 finalColor = mix(vec4(0.0, 0.0, 0.0, 0.0), color, alphaSmooth * alpha);

    // Añadir grano cinematográfico solo dentro de la zona roja
    if (dist < radius) {
        float grain = fractalNoise(vUv * resolution.xy * .7 + time * 5.0);
        finalColor.rgb -= grain * 0.2; // Ajustar la intensidad del grano para oscurecer
    }

    gl_FragColor = finalColor;
}

`;

export const REDEYE_VERTEX = `
    varying vec2 vUv;
    varying vec3 vPosition;

    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const REDEYE_GEOMETRY = new PlaneGeometry(1, 1, 1, 1);

export const REDEYE_MATERIAL = new ShaderMaterial({
    side: FrontSide,
    uniforms: {
        time: { value: 0 },
        x: { value: 0.5 },
        y: { value: 0.5 },
        radius: { value: 0.5 },
        timeLight: { value: 0 },
        resolution: { value: new Vector4() },
    },
    fragmentShader: REDEYE_FRAGMENT,
    vertexShader: REDEYE_VERTEX,
});

