import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Scene from '../3D/Scene';;
import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import { GetBy } from '../_app/cuchillo/core/Element';
import Header from '../layout/Header';
import { DataHolder } from '../DataHolder';

export default class Default extends Page {
  constructor() {
    super();
    
    DataHolder.update();
    Videos.init();
  }

  //SHOW
  beforeShow() {
    
    Header.update();
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Scroll.start();
    Scroll.show();
    Keyboard.mountPage(this.id);
    
    if (this.isFirstTime) {
     // Scene.init();
      WebGLSketch.start();
    }
  }

  show__effect(__call) {
   
    if (this.isFirstTime) {
      Wrap.show(() => {
        Header.show(5);
        Preloader.hide(() => {});
        this.afterShow();
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);
