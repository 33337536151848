import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import { Basics, isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Header from '../layout/Header';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Default from './Default';

export default class Legal extends Default {
  id;
  isWrapAutoRemove = true;

//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor() {
    super();
    Basics.oldPage = "legal";
    document.body.classList.add("--legal");
    if(!isSmartphone) Header.hide();
  }

  beforeShow() {
    Header.update(true);
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);
    
    if (this.isFirstTime) {
     // Scene.init();
      WebGLSketch.start();
    }
  }

  show__effect(__call) {
   
    if (this.isFirstTime) {
      Wrap.show(() => {
        if(isSmartphone) Header.show(5);
        Preloader.hide(() => {});
        this.afterShow();
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterHide() {
    document.body.classList.remove("--legal");
    Header.show();
    super.afterHide();
  }
}

ControllerPage._addPage("legal", Legal)
