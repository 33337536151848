
export class SwipeDetector {
  static element = null;
  static leftCallback = null;
  static rightCallback = null;
  static touchStartX = 0;
  static calls = {
    start: (e)=> this.handleTouchStart(e),
    end: (e)=> this.handleTouchEnd(e),
  }

  static init(leftCallback, rightCallback) {
    this.element = document;
    this.leftCallback = leftCallback;
    this.rightCallback = rightCallback;
    
    this.element.addEventListener("touchstart", this.calls.start);
    this.element.addEventListener("touchend", this.calls.end);
  }

  static dispose() {
    if (this.element) {
      this.element.removeEventListener("touchstart", this.calls.start);
      this.element.removeEventListener("touchend", this.calls.end);
      this.element = null;
      this.leftCallback = null;
      this.rightCallback = null;
      this.touchStartX = 0;
    }
  }

  static handleTouchStart(event) {
    this.touchStartX = event.touches[0].clientX;
  }

  static handleTouchEnd(event) {
   
    const touchEndX = event.changedTouches[0].clientX;
    const deltaX = touchEndX - this.touchStartX;

    if (Math.abs(deltaX) > 50) { // Umbral para swipe
      if (deltaX > 0) {
        this.rightCallback?.();
      } else {
        this.leftCallback?.();
      }
    }
  }
}
