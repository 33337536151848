import RedEye from '../3D/RedEye';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Header, { Header_Link } from '../layout/Header';
import DefaultNoScroll from './DefaultNoScroll';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Ease } from '../_app/cuchillo/utils/Ease';
import gsap, { Expo, Power2, Power3 } from 'gsap';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Wrap from '../layout/Wrap';
import Preloader from '../layout/Preloader';
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';

export default class ProjectInfo extends DefaultNoScroll {
//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  scroller;
  sliderDom;
  sliderHolder;
  _scale = 1;
  _oldX;
  _oldScale = 1;
  _scaleImg = 1;
  _title
  _items = [];

  constructor() {
    super();
    Basics.oldPage = "info";
    this.sliderDom = GetBy.class("block-project__slider", this.container)[0];
    this.sliderHolder = GetBy.class("__holder", this.container)[0];
    this._title =  new Project__Title(GetBy.class("__title", this.container)[0]);

    Header.hide();

    this.scroller = new InfiniteScroll(isSmartphone? InfiniteScroll.AXIS_X : InfiniteScroll.AXIS_Y, {
      domResize: this.sliderDom,
      container: this.sliderDom,
      multiplicator: 1,
      minSpeed: .5,
      inverted: false,
      gap: 0,
      hasVirtuaScroll: true
    });

    [...GetBy.selector("p", this.container)].map(dom => { this._items.push(dom); });
    [...GetBy.selector("h2", this.container)].map(dom => { this._items.push(dom); });
    [...GetBy.selector("h3", this.container)].map(dom => { this._items.push(dom); });
    [...GetBy.selector("h4", this.container)].map(dom => { this._items.push(dom); });
    [...GetBy.selector("div > div", this.container)].map(dom => { this._items.push(dom); });

    this._items.sort(() => Math.random() - 0.5);

    for (let i = 0; i < this._items.length; i++) {
      gsap.set(this._items[i],{opacity:0});
    }
  }

  afterShow() {
    super.afterShow();
    if(this.scroller.total_items > 2) {
      this.scroller.start();
      this.scroller.show();
    }
  }

  show__effect() {
    Header.mode = "WHITE";
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => {});
        this.afterShow()
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
    
    RedEye.getInstance().center();
    gsap.to(this.sliderHolder, {x:0, ease:Expo.easeOut, duration:.8, delay:.5});
    
    this._title.show();

    let delay = 1;
    for (let i = 0; i < this._items.length; i++) { 
      gsap.to(this._items[i],{opacity:1, ease:Power2.easeOut, duration:1, delay:delay + .02 * i});
    }
  }

  hide__effect() {
    this._title.hide();
    for (let i = 0; i < this._items.length; i++) { 
      gsap.to(this._items[i],{opacity:0, ease:Power2.easeOut, duration:.4, delay:0});
    }
    gsap.to(this.sliderHolder, {x:"100%", alpha:0, ease:Power2.easeOut, duration:.4, onComplete:()=> {}});
    RedEye.getInstance().hide();
    super.hide__effect();
  }

  beforeHide() {
    //super.beforeHide();
  }

  afterHide() {
    this.scroller.hide();
    this.scroller.dispose();
    Header.show();
    super.afterHide();
  }

  loop() {
    if(this.scroller.total_items > 2) {
      this.scroller.loop();
      RedEye.getInstance().power = 1 + Math.abs(this.scroller.speed * .06);

      if(!isSmartphone) {
    
        const scale1 = (Interaction.positions.mouse.x/Metrics.WIDTH)/4;
        const scale = Math.max(1, Interaction.positions.mouse.x/(Metrics.WIDTH * .7)) + scale1;
      
        this._scale = this._scale + (scale - this._scale) * .06;
        this.sliderDom.style[CSS.transform] = CSS.scale3D(this._scale,this._scale,1);

        /*if(!this._oldX) this._oldX = Interaction.positions.mouse.x;
        const dist = 1 + Math.abs(this._oldX - Interaction.positions.mouse.x) * .02;
        this._oldX = Interaction.positions.mouse.x;*/

        const dist = 1 + Math.abs(this._oldScale - scale) * .5;
        this._oldScale = this._scale;

        this._scaleImg += (dist - this._scaleImg) * .06;
        this.sliderDom.style.setProperty('--scale', `${this._scaleImg}`);
      }
    }
    
  }

  resize() {
    super.resize();
    this.scroller.resize();
  }
}

class Project__Title {
  container;
  _items = [];

  constructor(dom) {
    this.container = dom;

    /*    
    [...GetBy.class("__word", this.container)].map(dom => {
     
        const item = new Header_Link(dom)
        this._items.push(item);
     
    });*/
  }

  show() { 
    gsap.to(this.container, {opacity:1, ease:Power2.easeOut, duration:.2, delay:.5});
  }

  hide() { 
    gsap.to(this.container, {opacity:0, ease:Power2.easeOut, duration:.4, delay:.0});
  }
}

ControllerPage._addPage("project-info", ProjectInfo)
