import { gsap } from "gsap";

import { Scroll } from './_app/cuchillo/scroll/Scroll';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { GetBy } from "./_app/cuchillo/core/Element";
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Home from './pages/Home';
import About from './pages/About';
import Archive from './pages/Archive';
import Project from './pages/Project';
import ProjectInfo from './pages/ProjectInfo';
import Film from './pages/Film';
import Legal from './pages/Legal';
import Page404 from './pages/Page404';

import BG from './_app/cuchillo/layout/Background';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Header from './layout/Header';
import Loading from './layout/Loading';
import Wrap from './layout/Wrap';

import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';
import Preloader from "./layout/Preloader";
import Sidemenu from "./layout/Sidemenu";
import Language from "./_app/cuchillo/utils/Language";
import CMP from "./windows/Cmp";
import WebGLSketch from "./_app/cuchillo/3D/WebGLSketch";
import RedEye from "./3D/RedEye";
import Scene from "./3D/Scene";
import CircleSlider from "./3D/CircleSlider";
import FullSlider from "./3D/FullSlider";
import TexturesController from "./_app/cuchillo/3D/TexturesController";

export default class Main {
  static stats;
  static bgEye;
  static circleSlider;
  static fullSlider;

  static init() {
    gsap.ticker.fps(60);

    Basics.id = "yza_v001"; // ID para cookies   
    Language.init();

    TexturesController.isPreload = true;
    [...GetBy.selector("[data-preload-texture]")].map(item => {
      TexturesController.load({src:item.dataset.src});
    });
    
    Header.init();
    Sidemenu.init();
    WebGLSketch.init();
    WebGLSketch.start();

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    ControllerWindow.init(); // Control ventanas

    BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
    CMP.init(); // Checkea y saca el aviso de cookies

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    //LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.onComplete = () => {
      Main.start();
      Main.setup()
    };

    Scene.init();
    Preloader.init(()=> LoaderController.init());
    this.doCuchilloInfo();
  }

  static start() {
    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      this.setWorker();
      this.doCuchilloInfo();
      gsap.ticker.add(() => { Main.loop(); });
    }

    this.bgEye = new RedEye({dom:GetBy.id("scene-three")});
    this.circleSlider = new CircleSlider(GetBy.id("circle-slider"));
    this.fullSlider = new FullSlider(GetBy.id("full-slider"));
    
    Scene.add(this.bgEye);
    Scene.add(this.fullSlider);
    Scene.add(this.circleSlider);

    this.bgEye.init();
  }

  static setup() {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      //Cursor.start();
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      //Cursor.hide();
      Sidemenu.hide();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
    EventDispatcher.addEventListener(Sidemenu.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Sidemenu.ON_SHOW, () => {
      Scroll.setEnabled(false);
      ControllerWindow.hideAll();
    });
  }

  static resize() {
    Header.resize();
    Sidemenu.resize();
    InterfaceCanvas.resize();
    BG.resize();
    ControllerPage.resize();
    WebGLSketch.resize();

    //this.bgEye.resize();
    if(this.circleSlider) this.circleSlider.resize();
    if(this.fullSlider) this.fullSlider.resize();
    if(this.bgEye) this.bgEye.resize();
    
    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    if (Scroll.isScrolling) Scroll.loop();

    ControllerPage.loop();

    this.bgEye.loop();
    this.circleSlider.loop();
    this.fullSlider.loop();

    BG.loop();
    Sidemenu.loop();
    InterfaceCanvas.loop();
    WebGLSketch.loop();
  }

  static loopDebug() {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static doCuchilloInfo() {
    console.log('%cby Cuchillo', 'background: #000; color: #bada55; padding:25px 100px;');
    console.log('⟶ http://somoscuchillo.com');
    console.log('⟶ https://www.instagram.com/_cuchillo');
    console.log('⟶ https://twitter.com/somoscuchillo');
    console.log('⟶ https://twitter.com/mr__corrales');
    console.log('⟶ https://bsky.app/profile/corrales.bsky.social');
  }

  static setWorker() {
    if ('serviceWorker' in navigator && !isDebug) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
