import RedEye from '../3D/RedEye';
import { Basics } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { DataHolder } from '../DataHolder';
import Header from '../layout/Header';
import DefaultNoScroll from './DefaultNoScroll';

export default class About extends DefaultNoScroll {
//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor() {
    super();
    Basics.oldPage = "about";
    document.body.classList.add("--remove-footer");
    DataHolder.update();
    Header.hideTitle();
  }

  show__effect() {
    Header.mode = "WHITE";
    super.show__effect();
    RedEye.getInstance().bottom();
  }

  beforeHide() {
    document.body.classList.remove("--remove-footer");
    RedEye.getInstance().hide();
    //super.beforeHide();
  }
}

ControllerPage._addPage("about", About)
