import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Header, { Header_Link } from '../layout/Header';
import DefaultNoScroll from './DefaultNoScroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import Wrap from '../layout/Wrap';
import Preloader from '../layout/Preloader';
import { DataHolder } from '../DataHolder';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import WheelControls from '../_app/cuchillo/scroll/WheelControls';
import { SwipeDetector } from '../_app/cuchillo/components/SwipeDetector';

export default class Project extends DefaultNoScroll {
//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  scroller;

  _scale = 1;
  _oldX;
  _oldScale = 1;
  _scaleImg = 1;
  _title
  _items = [];
  _hasShowEffect = false;
  _wheel;

  constructor() {
    super();

    document.body.classList.add("--remove-footer");

    this._hasShowEffect = Basics.oldPage != "project" && Basics.oldPage != "film";
    
    Basics.oldPage = "project"; 
    this.projectId = GetBy.class("title", this.container)[0].dataset.id;
    
    const next = DataHolder.next(this.projectId);
    const prev = DataHolder.prev(this.projectId);

    Header.changeTitle(GetBy.class("title", this.container)[0].textContent, window.location, true);
    Header.changeNextPrev(next, prev);

    if(!isTouch) {
      this._wheel = new WheelControls({
        onForward:()=>{ ControllerPage.changePage(next.link, "replace") },
        onBackward:()=>{ ControllerPage.changePage(prev.link, "replace") },
      });
    } else {
      SwipeDetector.init(()=>{ ControllerPage.changePage(next.link, "replace") }, ()=>{ ControllerPage.changePage(prev.link, "replace") })
    }
  }

  afterShow() {
    super.afterShow();
    if(this._wheel) this._wheel.enabled = true;
  }

  show__effect() {
    Header.mode = this.container.dataset.menuBlack === "true"? "BLACK" : "INVERT";
    Header.enableAutoHide(true, 1);
    
    if (this.isFirstTime) {
      Wrap.show(() => {
        Header.show(5);
        Preloader.hide(() => {});
        this.afterShow()
      });
    } else {
      if(this._hasShowEffect) Wrap.show(() => { this.afterShow() }, {duration:.2, delay:0});
      else Wrap.show(() => { this.afterShow() }, {duration:0, delay:0});
    }
  }

  hide__effect() {
    Header.enableAutoHide(false);
    Wrap.hide(() => { this.afterHide(); }, {duration:.1, delay:0});
  }

  beforeHide() {
    document.body.classList.remove("--remove-footer");
    if(!isTouch) {
      this._wheel.dispose();
    } else {
      SwipeDetector.dispose();
    }
    //super.beforeHide();
  }

  afterHide() {
    /*this.scroller.hide();
    this.scroller.dispose();*/
    Header.show();
    super.afterHide();
  }

  loop() {
    //if(this.scroller.total_items > 2) {
    //  this.scroller.loop();
      
     
    
      /*this._scale = this._scale + (scale - this._scale) * .06;
      this.sliderDom.style[CSS.transform] = CSS.scale3D(this._scale,this._scale,1);

     

      const dist = 1 + Math.abs(this._oldScale - scale) * .5;
      this._oldScale = this._scale;

      this._scaleImg += (dist - this._scaleImg) * .06;
      this.sliderDom.style.setProperty('--scale', `${this._scaleImg}`);*/
   // }
    
  }

  resize() {
    super.resize();
    // this.scroller.resize();
  }
}

ControllerPage._addPage("project", Project)
