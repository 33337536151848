import { Videos } from '../_app/cuchillo/components/Videos';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Scene from '../3D/Scene';;
import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import { GetBy } from '../_app/cuchillo/core/Element';
import Header from '../layout/Header';
import { DataHolder } from '../DataHolder';

export default class DefaultNoScroll extends Page {
  constructor() {
    super();
    Videos.init();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Header.update();
    Keyboard.mountPage(this.id);
    
    if (this.isFirstTime) {
      
    }
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Header.show(5);
        Preloader.hide(() => {});
        this.afterShow()
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default_no_scroll', DefaultNoScroll);
