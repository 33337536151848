export const IMAGE_FRAGMENT = `
    precision mediump float;

    #define PI 3.1415926538

    uniform float imageScale;
    uniform float maskScale;
    uniform float opacity;
    uniform sampler2D texture1;
    uniform sampler2D textureOverlay;
    uniform sampler2D mask;
    uniform vec2 resolution;
    uniform vec2 mouse;
    uniform float scaleCenter;

    varying vec2 vUv;

    uniform float darken;

    void main() {
        vec2 uMouse = mouse * -0.5;
        // tip2: do the same for your mouse
        uMouse.y *= resolution.y / resolution.x;
        uMouse *= -1.;

        // Applies aspect ratio to plane
        vec2 newUV = (vUv - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);
        vec2 mouseUv = vUv + uMouse;
        vec2 newUVMouse = (mouseUv - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);

        // Gets new centered coords for the texture and scales the image
        vec2 textureUVScale = (newUV - scaleCenter) * 1. + scaleCenter;
        vec2 textureUVScaleMouse = (newUVMouse - scaleCenter) * imageScale + scaleCenter;
        vec2 maskUVScale = (newUV - scaleCenter) * maskScale + scaleCenter;

        // Gets texture pixel color
        vec4 textureColor = texture2D(texture1, textureUVScaleMouse);
        vec4 textureBlack = texture2D(textureOverlay, textureUVScale);
        vec4 maskColor = texture2D(mask, maskUVScale);

        // Darkens video
        vec4 darkened = mix(textureColor, textureBlack, darken);

        // Mixes with mask
        vec4 maskedColor = darkened * maskColor; 

        gl_FragColor = vec4(maskedColor.rgb * opacity, opacity);
    }
`;


// fragmentShader: [
//  “uniform sampler2D tDiffuse;”,
//  “uniform sampler2D tMask;”,
//  “varying vec2 vUv;”,
// “void main() {“,
//  // get the current pixel color
//  “vec4 texel = texture2D(tDiffuse, vUv);”,
//  // get alpha based on the color of the mask image
//  “float alpha = texture2D(tMask, vUv).r;”,
//  // apply the alpha to the current pixel
//  “gl_FragColor = texel * alpha;”,
//  “}”
// ].join(“\n”)