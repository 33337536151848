import { Group } from 'three';

import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';

export default class Scene {
    static group = new Group();

    static init() {
        WebGLSketch.group.add(this.group);
    }

    static start() {
        if (!this._running) this._running = true;
    }

    static stop() {
        if (this._running) this._running = false;
    }

    static add(element) {
        this.group.add(element);
    }

    static remove(element) {
        this.group.remove(element);
    }

    static update() { }

    static loop() {
        if (!this._running) return;

        this.update();
    }

    static resize() { }

    static dispose() {
        WebGLSketch.group.remove(this.group);
    }
}
