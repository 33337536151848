import { ShaderMaterial, FrontSide, Vector4 } from 'three';
import WebGLObject from '../_app/cuchillo/3D/WebGLObject';
import { REDEYE_GEOMETRY, REDEYE_FRAGMENT, REDEYE_VERTEX } from '../_app/cuchillo/3D/materials/RedEye';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import Keyboard, { KEYS } from '../_app/cuchillo/core/Keyboard';
import gsap, { Power1, Power2, Power3, Power4 } from 'gsap';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

export default class RedEye extends WebGLObject {
    static instance = null;

    tick = 0;
    tickLight = 0;
    blur = 0.1;
    x = 0.5;
    y = 0;
    radius = 0.5;
    alpha = 0;
    power = 1;

    constructor(opts = {}) {
        if (RedEye.instance) {
            return RedEye.instance;
        }

        super(opts);

        this.geometry = REDEYE_GEOMETRY.clone();
        this.material = new ShaderMaterial({
            side: FrontSide,
            uniforms: {
                time: { value: 0 },
                x: { value: this.x },
                y: { value: this.y },
                alpha: { value: this.alpha },
                blur: { value: this.blur },
                radius: { value: this.radius },
                power: { value: 1.0 },
                timeLight: { value: 0 },
                resolution: { value: new Vector4() },
            },
            fragmentShader: REDEYE_FRAGMENT,
            vertexShader: REDEYE_VERTEX,
        });

        this.init();
        RedEye.instance = this;
    }

    static getInstance(opts = {}) {
        if (!RedEye.instance) {
            RedEye.instance = new RedEye(opts);
        }
        return RedEye.instance;
    }

    show() {
        gsap.killTweensOf(this);
        this.y = 0.5;
        this.alpha = 0;
        gsap.to(this, { alpha: 1, radius: isSmartphone? 0.24 : 0.5, blur: 0.1, duration: 2, ease: Power4.easeOut });
    }

    hide() {
        gsap.killTweensOf(this);
        gsap.to(this, { alpha: 0, radius: 2, blur: 1, duration: .4, ease: Power1.easeIn });
    }

    center() {
        gsap.killTweensOf(this);
        this.show();
        this.power = 1;
        if(isSmartphone) {
            this.y = 0.70;
            gsap.to(this, { x: 0.5, y: 0.70, duration: 0.4, ease: Power3.easeOut });
        } else {
            gsap.to(this, { x: 0.5, y: 0.5, duration: 0.4, ease: Power3.easeOut });
        }
        
    }

    centerSmall() {
        gsap.killTweensOf(this);
        
        this.radius = .05;
        this.x = .5;
        this.y = .5;
        gsap.to(this, { radius:.2, alpha: 2, duration: 2, ease: Power4.easeOut, delay: 0 });
        
    }

    bottom() {
        gsap.killTweensOf(this);
        this.power = 1;
        this.radius = 3;
        this.alpha = 0;
        this.blur = 0.8;
        this.y = -this.radius * 0.85;

        gsap.to(this, { x: 0.5, y: 0 - this.radius * 0.81, alpha: 2, duration: 6, ease: Power4.easeOut, delay: 0 });
    }

    init() {
        super.init();
        this.resize();
    }

    loop() {
        this.tick = this.tick + 1;

        if (this.tick % 2 == 0) {
            this.material.uniforms.alpha.value = this.alpha;
            this.material.uniforms.blur.value = this.blur;
            this.material.uniforms.x.value = this.x;
            this.material.uniforms.y.value = this.y;
            this.material.uniforms.radius.value = this.radius;
        }
        if (this.tick % 4 == 0) {
            this.tickLight = this.tickLight + 1;
            this.material.uniforms.timeLight.value = this.tickLight;
        }
        if(this.tick % 7 == 0) {
            this.material.uniforms.time.value = Maths.maxminRandom(100, 0);
        }

        this.material.uniforms.power.value += (this.power - this.material.uniforms.power.value) * .06;
    }

    resize() {
        // Ajustar posición y tamaño de acuerdo a las métricas
        this.position.x = 0;
        this.position.y = 0;
        super.resize(Metrics.WIDTH, Metrics.HEIGHT);

        // Actualizar la resolución en los uniformes del shader
        const width = Metrics.WIDTH;
        const height = Metrics.HEIGHT;

        this.material.uniforms.resolution.value.set(width, height, 1.0, 1.0);
    }
}
