import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";
import { isDebug } from '../_app/cuchillo/core/Basics';
import Header from "./Header";
import CircleSlider from "../3D/CircleSlider";
import TexturesController from "../_app/cuchillo/3D/TexturesController";
import FullSlider from "../3D/FullSlider";
import Main from "../main";

export default class Preloader {
    _container;
    _callback;
    
    static init (__call) {
        GetBy.id('Preloader').style.display = "none";
        setTimeout(()=> {
            Header.showFirst();

            TexturesController.start({call:()=>{
                TexturesController.isPreload = false;
                this._callback();        
        
                /*setTimeout(()=> {   CircleSlider.getInstance().preshow(); }, 1500);
                setTimeout(()=> {   Header.show(5);}, 3000);
                setTimeout(()=> {   this._callback()}, 6000);*/
            }});
        }, 500);
        

        this._callback = __call;
        this._container = GetBy.id('Preloader');        
    }

    static show() {

    }

    static update (__progress) {

    }

    static hide (__call) {
       /* if(isDebug) {
            if(__call) __call();
            return;
        }

        gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            scaleX: 1,
            ease: Power1.easeOut,
            duration: .1
        });
        gsap.to(this._container, {
            duration: .1,
            opacity: 0,
            delay: 0,
            onComplete: () => {
            this._container.style.display = "none";
            if(__call) __call();
            }
        });*/

       
    }
}
