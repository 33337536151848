import { Basics, isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { DataHolder } from '../DataHolder';
import Header from '../layout/Header';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Default from './Default';

export default class Archive extends Default {
  _links;
  _calls = {
    over: this.hoverProject.bind(this),
    out: this.outProject.bind(this)
  }
  _timer;

  constructor() {
    super();
    Basics.oldPage = "archive";
    // Selecciona todos los enlaces dentro del contenedor
    DataHolder.update();

    if(!isTouch) {
      setTimeout(()=> {
        this.setupLinks();
      }, 1000);
    }
  }

  beforeShow() {
    Header.title.hide();
    Header.mode = "INVERT";
    super.beforeShow();
  }

  setupLinks() {
    this._links = GetBy.selector("a", this.container);

    // Añadir los listeners de eventos a cada enlace
    this._links.forEach(link => {
      link.addEventListener(Basics.mouseOver, this._calls.over);
      link.addEventListener(Basics.mouseOut, this._calls.out);
    });
  }

  /*show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      },{delay:.5});
    } else {
      Wrap.show(() => { this.afterShow() },{delay:.5});
    }
  }*/

  hoverProject(e) {
    clearTimeout(this._timer);
    Header.changeTitle(e.target.dataset.title, e.target.href);
  }

  outProject(e) {
    clearTimeout(this._timer);
    this._timer = setTimeout(()=> { Header.hideTitle();}, 300);
   
  }

  _dispose() {
    super._dispose();

    if(!isTouch) {
      this._links.forEach(link => {
        link.removeEventListener(Basics.mouseOver, this._calls.over);
        link.removeEventListener(Basics.mouseOut, this._calls.over);
      });
    }
  }
}

ControllerPage._addPage("archive", Archive)
