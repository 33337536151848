import BezierEasing from 'bezier-easing';

import { CSS } from '../utils/CSS';
import { Maths } from '../utils/Maths';
import InfiniteScroll from './InfiniteScroll';
import { Metrics } from '../core/Metrics';

export default class InfiniteScroll__Item {
    static easing = BezierEasing(0.12, 0.67, 0.36, 0.93);

    dom;
    id;
    indice;
    index;
    height;
    width;
    top;
    left;
    hasMove = true;
    progress = 0;
    speed = 0;
    direction = 1;
    tick = 0;
    
    _x = 0;
    _y = 0;
    _z = 0;

    _p0;
    _p1;
    _p0Inside;
    _p1Inside;

    opts = {
        speed: {
            y: 1,
            x: 1,
            z: 1
        },
        offset: 0,
        rotation: {
            angle: 10,
            max: 1,
            min: .9
        }
    };

    _axis = 'y';
    _domAxis = 'top';
    _measure = 'height';
    _offsetAxis = 'offsetTop';
    _offsetSize = 'offsetHeight';

    get realY() { return this.top + this._y; }
    get y() { return this._y; }
    set y(__n) {
        this._y = __n * this.opts.speed.y;
        this.update();
    }

    get realX() { return this.left + this._x; }
    get x() { return this._x; }
    set x(__n) {
        this._x = __n * this.opts.speed.x;
        this.update();
    }

    get z() { return this._z; }
    set z(__n) {
        this._z = __n * this.opts.speed.z;
        this.update();
    }

    get progressItem() {
        return Maths.normalize(this._p0, this._p1, this[this._axis]);
    }
    
    get progressInside() {
        return Maths.normalize(this._p0Inside, this._p1Inside, this[this._domAxis] + this[this._axis]);
    }

    constructor(__d, __i, __axis = InfiniteScroll.AXIS_Y, __hasMove = true) {
        this.dom = __d;
        this.indice = __i;
        this.index = __i;
        this.id = this.getId();
        this.hasMove = __hasMove;

        switch (__axis) {
            case InfiniteScroll.AXIS_Y:
                this._axis = 'y';
                this._measure = 'height';
                this._domAxis = 'top';
                this._offsetAxis = 'offsetTop';
                this._offsetSize = 'offsetHeight';
                break;

            case InfiniteScroll.AXIS_X:
                this._axis = 'x';
                this._measure = 'width';
                this._domAxis = 'left';
                this._offsetAxis = 'offsetLeft';
                this._offsetSize = 'offsetWidth';
                break;
        }

        this.resize();
        this.resizeLimits();
        //this.update();
    }

    place(baseSize, gap) {
        const position = this.index * (baseSize + gap);
        if(this.hasMove) {
            this.dom.style[this._domAxis] = position + 'px';
        }
        this[this._domAxis] = position;
        
        this.resetHook();
    }

    resetHook(){}

    getId() {
        if (!this.dom.getAttribute('id')) this.dom.setAttribute('id', '__' + new Date().getTime() + '__' + this.index);
        return this.dom.getAttribute('id');
    }

    update() {
        this.progress = this.progressItem;

        this.draw();
        this.drawHook();
    }

    draw() {
        if(this.hasMove) {
            this.dom.style[CSS.transform] = CSS.matrix3D(this._x, this._y, this._z);
        }
    }

    drawHook() { }

    visible() { }

    show() { }

    hide() { }

    resize() {
        
        if(this.hasMove) {
            this.dom.style = '';
            this.dom.style.position = 'absolute';
        }

        this.height = this.dom.offsetHeight;
        this.width = this.dom.offsetWidth;
        this.top = this.dom.offsetTop;
        this.left = this.dom.offsetLeft;
    }

    resizeLimits() {
        this._p0 = -(this.opts.offset + this[this._measure] + this[this._domAxis]);
        this._p1 = this.opts.offset - this[this._domAxis];

        this._p0Inside = 0;
        this._p1Inside = Metrics.WIDTH - this[this._measure];
        
    }

    dispose() {
        for (let i = 0; i < this._nInsiders; i++) {
          this._insiders[i].dispose();
        }
    
        this._nInsiders = 0;
        this._insiders = [];
        this.dom.style[CSS.transform] = CSS.translate3D(0, 0, 0);
        this.dom = null;
      }
}
