import RedEye from '../3D/RedEye';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Header, { Header_Link } from '../layout/Header';
import DefaultNoScroll from './DefaultNoScroll';
import Wrap from '../layout/Wrap';
import Preloader from '../layout/Preloader';
import { Basics } from '../_app/cuchillo/core/Basics';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class Page404 extends DefaultNoScroll {
//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor() {
    super();
    Basics.oldPage = "404";
    document.body.classList.add("--remove-footer");
  }

  show__effect() {
    
    super.show__effect();
    Header.mode = "INVERT";
    Header.changeTitle("404. Page not found", window.location, true);
    RedEye.getInstance().centerSmall();
  }

  beforeHide() {
    document.body.classList.remove("--remove-footer");
    RedEye.getInstance().hide();
  }

  loop() {
    RedEye.getInstance().power = Maths.maxminRandom(500,-200)/10;
  }
}

ControllerPage._addPage("404", Page404)
