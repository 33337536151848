export const IMAGE_VERTEX = `
    varying vec2 vUv;

    uniform float cylinderEffect; // Valor de 1 a -1 para definir qué mitad afectará el efecto cilíndrico
    uniform float direction; // Valor de 1 a -1 para definir qué mitad afectará el efecto cilíndrico

    void main() {
        vUv = uv;

        vec3 transformedPosition = position;
       
        gl_Position = projectionMatrix * modelViewMatrix * vec4(transformedPosition, 1.0);
    }
`;

export const IMAGE_VERTEX2 = `
    varying vec2 vUv;

    void main() {
        vUv = uv;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;
