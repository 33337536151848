import { TextureLoader } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

export default class TexturesController {
  static LOADED = 'loaded';
  static LOADING = 'loading';
  static EMPTY = 'empty';

  static loaders = {
    simple: new TextureLoader(),
    rgbe: new RGBELoader()
  }

  static emptyTexture = this.loaders.simple.load('/assets/images/b.png');

  static opts = {
    max: 10,
    preloads: 5
  }

  static getLoader(src) {
    const extension = src.split('.').pop().toLowerCase();

    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'webp':
        return this.loaders.simple;
      case 'hdr':
      case 'pic':
        return this.loaders.rgbe;
      default:
        return this.loaders.simple; // Reemplaza 'simple' con el tipo de loader que desees para estas extensiones
    }
  }

  static isPreload = false;
  static actual = 0;
  static textures = {};
  static loadQueue = [];

  static _getId(__src) { return __src.split('/').join('_').split('.').join('_').toLowerCase(); }

  static start(opts) {
    this.opts = {
      ...this.opts,
      ...opts
    };

    this._nextLoad();
  }

  static load(__opts) {
    const { src, call, material, attribute } = __opts;
    const id = this._getId(src);

    // Si tenemos material le metemos una textura vacia
    if (material) {
      material[attribute] = this.emptyTexture;
    }

    // Cuando la textura se cargue tiramos por aqui
    const loaded = (texture) => {
      if (material) {
        material[attribute] = texture;
      }

      if (call) {
        call(texture);
      }
    }

    // Cuando la queramos quitar la textura
    const cancel = (texture) => {
      if (material) {
        material[attribute] = this.emptyTexture;
      }
    }

    if (this.textures[id]) {
      const textureItem = this.textures[id];

      if (textureItem.state === TexturesController.LOADED) {
        loaded(textureItem.texture);
      } else {
        textureItem.onLoaded.push(loaded);
        textureItem.onCancel.push(cancel);
      }

    } else {
      const newItem = {
        id,
        src,
        onLoaded: [loaded],
        onCancel: [cancel],
        state: TexturesController.EMPTY,
        texture: null,
        cancel: false
      };

      this.textures[id] = newItem;
      this.loadQueue.push(id);
    }

    if (!this.isPreload) {
      this._nextLoad();
    }

    return id;
  }

  static _itemLoaded(__item, __texture) {
    const { onLoaded } = __item;

    __item.state = TexturesController.LOADED;
    __item.texture = __texture;

    if (onLoaded) {
      onLoaded.map(call => {
        call(__texture)
      })
    }
  }

  static _nextLoad() {
    if (this.actual < this.opts.max && this.loadQueue.length > 0) {
      const id = this.loadQueue.shift();
      const item = this.textures[id];

      if (item.cancel) {
        this._nextLoad();
        return;
      }

      this.actual++;
      item.state = this.LOADING;

      this.getLoader(item.src).load(item.src, (texture) => {
        if (!item.cancel) {
          this._itemLoaded(item, texture);
        }

        this.actual--;
        this._nextLoad();
      });
    }

    if (this.loadQueue.length === 0 && this.isPreload) {
      this.isPreload = false;
      this.opts.call();
    }
  }

  static cancel(__id, __onlyLoading = false) {
    const item = this.textures[id];
    const { onCancel } = item;

    if (!item) return;

    item.cancel = true;

    if (item.state === TexturesController.LOADING) {
      item.texture.abort();
      this.actual--;
    } else if (item.state === TexturesController.LOADED && !__onlyLoading) {
      item.texture.dispose();
      item.texture = null;
    }

    if (onCancel) {
      onCancel.map(call => call());
    }

    this.textures[id].state = this.EMPTY;
  }

  static reset() {
    this.textures = {};
    this.loadQueue = [];
    this.isPreload = true;
  }
}