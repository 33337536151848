import RedEye from '../3D/RedEye';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Header, { Header_Link } from '../layout/Header';
import DefaultNoScroll from './DefaultNoScroll';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Ease } from '../_app/cuchillo/utils/Ease';
import gsap, { Expo, Power2, Power3 } from 'gsap';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import CircleSlider from '../3D/CircleSlider';
import FullSlider from '../3D/FullSlider';
import WheelControls from '../_app/cuchillo/scroll/WheelControls';
import Wrap from '../layout/Wrap';
import Preloader from '../layout/Preloader';
import { DataHolder } from '../DataHolder';
import { Basics } from '../_app/cuchillo/core/Basics';

export default class Home extends DefaultNoScroll {
//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  scroller;
  
  constructor() {
    super();
    Basics.oldPage = "home";
    document.body.classList.add("--remove-footer");
    DataHolder.update();
    CircleSlider.getInstance().init();
    FullSlider.getInstance().init();
  }

  beforeShow() {
    Header.title.hide();
    super.beforeShow();
  }

  afterShow() {
    super.afterShow();
    CircleSlider.getInstance().show();
  }

  show__effect(__call) {
    Header.mode = "WHITE";
    Wrap.directShow();
    
    if (this.isFirstTime) {
      Preloader.hide(() => { this.afterShow() }); 
      CircleSlider.getInstance().preshow();
      setTimeout(()=> {   Header.show(5); FullSlider.getInstance().enabled = true;}, 1500);
      if(__call)  setTimeout(()=> {   __call()}, 4500);
    } else {
      this.afterShow();
      setTimeout(()=> {FullSlider.getInstance().enabled=true}, 1000);
    }
  }

  hide__effect() {
    CircleSlider.getInstance().hide();
    FullSlider.getInstance().hide(false);
    Wrap.hide(() => { this.afterHide() }, {duration:.5});
  }

  beforeHide() {
    document.body.classList.remove("--remove-footer");
    super.beforeHide();
  }
  afterHide() {
    /*this.scroller.hide();
    this.scroller.dispose();*/
    FullSlider.getInstance().stop();
    Header.mode = "INVERT";
    super.afterHide();
  }

  loop() {
    /*if(this.scroller.total_items > 2) {
      this.scroller.loop();
    }*/

    
  }

  resize() {
    super.resize();
  }
}

ControllerPage._addPage("home", Home)
