const DataHolder = {
  projects: PROJECTS_LIST,

  update: function() {
    const projectElements = document.querySelectorAll('li[scroll-item] a[data-project]');
        
   if (projectElements.length > 0) {
      this.projects = Array.from(projectElements).map(element => ({
        id: element.dataset.id,
        link: element.href,
      }));
    } else {
      this.projects = PROJECTS_LIST;
    }
  },  

  next: function(id) {
    const index = this.projects.findIndex(project => project.id === id);

    if (index !== -1 && index < this.projects.length - 1) {
      return this.projects[index + 1];
    } else if (index === this.projects.length - 1) {
      // Si es el último elemento, volvemos al primero
      return this.projects[0];
    }
    return null; // Si no se encuentra el ID o la lista está vacía
  },

  prev: function(id) {
    const index = this.projects.findIndex(project => project.id === id);
    if (index > 0) {
      return this.projects[index - 1];
    } else if (index === 0) {
      // Si es el primer elemento, volvemos al último
      return this.projects[this.projects.length - 1];
    }
    return null; // Si no se encuentra el ID o la lista está vacía
  }
};

export { DataHolder };