import gsap, { Power2 } from "gsap";
import { C, GetBy } from "../_app/cuchillo/core/Element";
import { Basics, isSmartphone } from "../_app/cuchillo/core/Basics";
import { Metrics } from "../_app/cuchillo/core/Metrics";
import { Functions } from '../_app/cuchillo/utils/Functions';
import { Interaction } from "../_app/cuchillo/core/Interaction";


export default class Header {
  static _items = [];
  static _subsOpen = false;
  static currentParent = null;
  static title = null;
  static next = null;
  static prev = null;
  static close = null;
  static _mode = null;
  static isCursorOver = false;

  static get mode() { return this._mode }
  static set mode(__mode) {
    if(this._mode === __mode) return;
    this._mode = __mode;

    switch(__mode) {
      case "INVERT":
        this.container.classList.remove("--black");
        this.container.classList.add("--invert");
        this.showAlpha();
        break;
      case "WHITE":
        this.container.classList.remove("--black");
        this.container.classList.remove("--invert");
        this.showAlpha();
        break;
      case "BLACK":
        this.container.classList.add("--black");
        this.container.classList.remove("--invert");
        this.showAlpha();
        break;
      case "AUTOHIDE":
        this.container.classList.add("--invert");
        break;
    }
  }

  static init() {
    Header.container = !isSmartphone? GetBy.id("Header") : GetBy.id("HeaderMobile");
    Header.container.addEventListener(Basics.mouseOver, ()=> this.isCursorOver = true);
    Header.container.addEventListener(Basics.mouseOut, ()=> {
      this.isCursorOver = false;
      this.title.checkWaitingHide();
    });
    
    const selector = isSmartphone? "data-remove-smartphone" : "data-remove-desktop"; 
    [...GetBy.selector(`[${selector}]`)].map((item)=>C.remove(item));

    [...GetBy.selector("[data-button]", this.container)].map(dom => {
      
      if(!dom.href) {
        const item = new Header_Link(dom)
        this._items.push(item);
      } else if(dom.href.indexOf("#work") < 0) {
        const item = new Header_Link(dom)
        this._items.push(item);
        if(item.isClose) this.close = item;

        if(dom.href.indexOf("#next") > -1) this.next = item;
        if(dom.href.indexOf("#prev") > -1) this.prev = item;
      } else {
        this.title = new Header_TitleLink(dom);
        this.title.hide();
      }
    });
  }

  static showFirst() {
    this._items[0].directShow(0);
  }

  static show(__delayInc = 1) {
    let delay = 0;
    for (let i = 0; i < this._items.length; i++) {
      if(!this._items[i].parent && !this._items[i].isClose) {
        this._items[i].show(delay);
        delay = delay + __delayInc;
      }
    }

    this.close.hide();
  }

  static enableAutoHide(__enable = true, __delay = 0) {
    if(__enable) {
      clearTimeout(Interaction.timerMove);
      Interaction.timerMove = null;
      Interaction.options.onMoveFirst = () => { Header.showAlpha(); }
      Interaction.options.onStop = () => {  Header.hideAlpha(); }
      this.hideAlpha(__delay);
    } else {
      clearTimeout(Interaction.timerMove);
      Interaction.timerMove = null;
      Interaction.options.onMoveFirst = null;
      Interaction.options.onStop = null;
      this.showAlpha();
    }
  }

  static showAlpha() {
    gsap.killTweensOf(this.container);
    gsap.to(this.container, {"--opacity":1, ease:Power2.easeOut, duration:.2});
  }

  static hideAlpha(delay=0) {
    gsap.killTweensOf(this.container);
    gsap.to(this.container, {"--opacity":0, ease:Power2.easeIn, duration:.2, delay:delay});
  }

  static hide() {
    let delay = 0;
    for (let i = 0; i < this._items.length; i++) {
      this._items[i].hide(delay, true);
      delay = delay + 0.25;
    }

    this.title.hide(0, true);
    if(!isSmartphone) this.close.show(3);
  }

  static changeTitle(__title, __href, __currentPage) {
    this.title.change(__title, __href, __currentPage)
  }

  static changeNextPrev(__next, __prev) {
    this.next.change(__next.link);
    this.prev.change(__prev.link);
  }

  static hideTitle() {
    this.title.hide();
  }
  
  static update(__isNeccesaryClose = false) {
    const currentDirect = GetBy.selector("[data-header-sub]")[0];
    const current = GetBy.selector("[aria-current='page']", this.container)[0];

    if(current) {
      
      const isNeccesaryClose = current.dataset.parent? __isNeccesaryClose : true;

      if(current.getAttribute("id") == this.currentParent) return;
      if(!isNeccesaryClose) this.currentParent = current.getAttribute("id");

      this.openSubs(current.getAttribute("id"), isNeccesaryClose);
    } else {
      this.openSubs("void", true);
    }
    
    if(currentDirect) {
      this.openSubs(currentDirect.dataset.headerSub, false);
    }
  }

  static resize() {
    this._items.map(item => item.resize());
    this.title.resize();
  }

  static openSubs(__parent, __isNeccesaryClose = true) {
    let delay = this._subsOpen? 1 : 0;
    this._subsOpen = false;

    for (let i = 0; i < this._items.length; i++) {

      if (this._items[i].parent === __parent) {
        this._subsOpen = true;
        this._items[i].show(delay);
        delay = delay + 1;
      } else if(this._items[i].parent && __isNeccesaryClose) {
        this._items[i].hide();
      }
    }
  }

  static closeSubs() {
    for (let i = 0; i < this._items.length; i++) {
      if(this._items[i].parent) {
        this._items[i].hide();
      }
    }
  }
}

class Header_TitleLink {
  container;
  info;
  id;
  span;
  isOpen = false;
  isRunning = false;
  parent;
  actual = "";
  
  _widthOpen;
  _widthTarget;
  _width = 0;
  _easing = .1;
  _height;

  _topTarget = 100;
  _top = 100;
  _nTitles = 0;
  _topHolder = 0;
  _topHolderTarget = 0;

  _idTimer;

  _calls = {
    loop: () => this.loop(),
    clik: () => this.loop()
  }

  get width() { return this._width; }
  set width(__n) {
    this._width = __n;
    this.container.style.width = `${this._width}px`;
  }

  get top() { return this._top; }
  set top(__n) {
    this._top = __n;
    this.span.style.transform = `translate3D(0, ${this._top}%, 0)`;
  }

  get topHolder() { return this._topHolder; }
  set topHolder(__n) {
    this._topHolder = __n;
    this.holder.style.transform = `translate3D(0, ${this._topHolder}px, 0)`;
  }

  constructor(dom) {
    this.container = dom;
    this.info = GetBy.selector("[data-info]")[0];
    this.holder = GetBy.selector("span", this.container)[0];
    this.span = GetBy.selector("span", this.container)[1];
    this.span.textContent = " ";
    this.id = this.container.getAttribute("id");
    this.parent = this.container.dataset.parent;
    this.top = 100;
   
    this.resize();
  }

  change(__title, __href, __currentPage) {
    this.waitingCall = null;
    
    if(__currentPage) {
      this.container.setAttribute("aria-current", "page");
    }
    
    if(this.actual == __title) {
      return;
    }

    this.actual = __title;

    this.container.setAttribute("href", __href);
    this.info.setAttribute("href", `${__href}details/`);

    this.newSpan = document.createElement("span");
    this.newSpan.textContent = __title;
    this.holder.appendChild(this.newSpan);
    gsap.set(this.container, {opacity:1});

    this._widthOpen = this.newSpan.offsetWidth;
    this._widthTarget = this._widthOpen;

    this._nTitles++;
    
    this._topHolderTarget = -this._height *  this._nTitles;
  }

  show(delay = 0) {
    if(this.isOpen) return;
    this.isOpen = true;
    gsap.killTweensOf(this.container);
    gsap.set(this.container, {opacity:1});
    
    clearTimeout(this._idTimer);
    this._idTimer = setTimeout(()=> {
      this._widthTarget = this._widthOpen;
      this._topTarget = 0;
      gsap.ticker.add(this._calls.loop);
    }, delay * 100);
  }

  directShow(delay = 0) {
    if(this.isOpen) return;
    this.isOpen = true;
    gsap.killTweensOf(this.container);
    gsap.set(this.container, {opacity:1});
  
    clearTimeout(this._idTimer);
    this._idTimer = setTimeout(()=> {
        this.width =
          this._widthTarget =
            this._widthOpen;

        this.top =
          this._topTarget =
            this._top;
    }, delay * 100);
  }

  hide(delay = 0, alpha = false) {
    this.actual = null;
    this.isOpen = false;
    clearTimeout(this._idTimer);

    if(Header.isCursorOver) {
      this.waitingCall = () => {
        this.hide(delay,alpha);
      }

      return;
    }

    this.waitingCall = null;
    this._idTimer = setTimeout(()=> {
      this._widthTarget = 0;
      this._topTarget = 100;
      gsap.ticker.add(this._calls.loop);
    }, delay * 100);

    if(alpha) {
      gsap.to(this.container,{opacity:0,ease:Power2.easeOut,duration:.2});
    }
  }

  checkWaitingHide() {
    if(this.waitingCall) {
      clearTimeout(this._idTimer);
      this._idTimer = setTimeout(()=> {
        if(this.waitingCall) this.waitingCall();
      },100);
    }
  }

  enableLoop() {
    if(this.isRunning) return;
    this.isRunning = true;

    gsap.ticker.add(this._calls.loop);
  }

  disableLoop() {
    if(!this.isRunning) return;
    this.isRunning = false;

    gsap.ticker.remove(this._calls.loop);
  }

  resize() {
    this._height = this.span.getBoundingClientRect().height;//Metrics.parseSize("12fpx");
    
    this._widthOpen = this.span.offsetWidth;
    
    this.topHolder =
      this._topHolderTarget = -this._height *  this._nTitles;

    if(this.isOpen) {
      this._widthTarget =
        this.width = this._widthOpen;
    }
  }

  loop() {
    this.speed = (this._widthTarget - this._width) * this._easing;
    this.width = this._width + this.speed;

    if(this.isOpen) this.speedTop = (this._topTarget - this._top) * .2;
    else this.speedTop = (this._topTarget - this._top) * .2;
    
    this.top = this._top + this.speedTop;

    this.speedTopHolder = (this._topHolderTarget - this._topHolder) * .2;
    
    this.topHolder = this._topHolder + this.speedTopHolder;
  }
}

export class Header_Link {
  container;
  id;
  span;
  isOpen = false;
  isRunning = false;
  parent;
  
  _widthOpen;
  _widthTarget;
  _width = 0;
  _easing = .1;

  _topTarget = 100;
  _top = 100;

  _idTimer;

  isClose = false;

  _calls = {
    loop: () => this.loop(),
    clik: () => this.loop()
  }

  get width() { return this._width; }
  set width(__n) {
    this._width = __n;
    this.container.style.width = `${this._width}px`;
  }

  get top() { return this._top; }
  set top(__n) {
    this._top = __n;
    this.span.style.transform = `translate3D(0, ${this._top}%, 0)`;
  }

  constructor(dom) {
    this.container = dom;
    this.span = GetBy.selector("span", this.container)[1];
    this.id = this.container.getAttribute("id");
    this.parent = this.container.dataset.parent;
    this.top = 100;
    this.width = 0;

    this.isClose = Functions.getId(dom) == "HeaderClose";
    this.resize();
  }

  change(__href) {
    this.container.setAttribute("href", __href);
  }

  show(delay = 0) {
    if(this.isOpen) return;
    this.isOpen = true;

    this.container.removeAttribute("data-disabled");
    gsap.set(this.container,{opacity:1});

    clearTimeout(this._idTimer);
    this._idTimer = setTimeout(()=> {
      this._widthTarget = this._widthOpen;
      this._topTarget = 0;
      gsap.ticker.add(this._calls.loop);
    }, delay * 100);
  }

  directShow(delay = 0) {
    if(this.isOpen) return;
    this.isOpen = true;
    
    gsap.killTweensOf(this.container);
    gsap.set(this.container, {opacity:1});
  
    clearTimeout(this._idTimer);
    this._idTimer = setTimeout(()=> {
      this.width =
      this._widthTarget = this._widthOpen;
      this.top =
      this._topTarget = 0;
      gsap.ticker.add(this._calls.loop);
    }, delay * 100);
  }

  hide(delay = 0, alpha = false) {
    if(!this.isOpen) return;

    this.container.setAttribute("data-disabled", "true");

    this.isOpen = false;
    clearTimeout(this._idTimer);

    this._idTimer = setTimeout(()=> {
      this._widthTarget = 0;
      this._topTarget = 100;
      gsap.ticker.add(this._calls.loop);
    }, delay * 100);

    if(alpha) {
      gsap.to(this.container,{"--opacity":0,ease:Power2.easeOut,duration:.2});
    }
  }

  enableLoop() {
    if(this.isRunning) return;
    this.isRunning = true;

    gsap.ticker.add(this._calls.loop);
  }

  disableLoop() {
    if(!this.isRunning) return;
    this.isRunning = false;

    gsap.ticker.remove(this._calls.loop);
  }

  resize() {
    
    this._widthOpen = this.span.offsetWidth;

    if(this.isOpen) {
      this._widthTarget =
        this.width = this._widthOpen;
    }
  }

  loop() {
    this.speed = (this._widthTarget - this._width) * this._easing;
    this.width = this._width + this.speed;

    if(this.isOpen) this.speedTop = (this._topTarget - this._top) * .2;
    else this.speedTop = (this._topTarget - this._top) * .2;
    
    this.top = this._top + this.speedTop;
  }
}

