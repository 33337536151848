import WebGLObject from './WebGLObject';
import TexturesController from './TexturesController';
import { PLANE_GEOMETRY } from '../../../3D/constants';
import { FrontSide, ShaderMaterial } from 'three';
import CanvasColorTexture from './CanvasColorTexture';
import { IMAGE_FRAGMENT } from '../shaders/image-fragment';
import { IMAGE_VERTEX } from '../shaders/image-vertex';
import { VideoTexture } from 'three/src/Three.js';
import { GetBy } from '../core/Element';
import { isSmartphone } from '../core/Basics';

export default class Image extends WebGLObject {
    texture;
    isImageLoaded = false;
    isVideoLoaded = false;
    isRunning = false;
    video = null;

    constructor(opts = {}) {
        super(opts);

        if (!this.geometry) {
            this.geometry = PLANE_GEOMETRY;
        }

        if (!this.material) {
            this.setupMaterial();
        }

        this.video = GetBy.tag('video', this.dom)[0];
        this.init();
    }

    init() {
        super.init();
        this.loadTexture();
        this.resize();
    }

    pause() {
        if(this.video && this.isRunning && !isSmartphone) this.video.pause();
        this.isRunning = false;
    }

    play() {
        if(this.video && !this.isRunning && !isSmartphone) this.video.play();
        this.isRunning = true;
    }

    setupMaterial() {
        this.material = new ShaderMaterial({
            uniforms: {
                texture1: { type: 't', value: new CanvasColorTexture().texture },
                mask: { type: 't', value: new CanvasColorTexture().texture },
                textureOverlay: { type: 't', value: new CanvasColorTexture('#000000').texture },
                opacity: { type: 'f', value: 1 },
                imageScale: { type: 'f', value: 1 },
                darken: { type: 'f', value: 0 },
                cylinderEffect: { type: 'f', value: 0 },
                direction: { type: 'f', value: 0 },
                scaleCenter: { type: 'f', value: 0 },
                resolution: {
                    type: 'v2',
                    value: { x: 1, y: 1 }
                },
                mouse: {
                    type: 'v2',
                    value: { x: 0, y: 0 }
                }
            },
            fragmentShader: IMAGE_FRAGMENT,
            vertexShader: IMAGE_VERTEX,
            transparent: false,
            side: FrontSide
        });
    }

    loadTexture() {
        if (this.dom.dataset.src) {
            TexturesController.load({
                src: this.dom.dataset.src,
                call: (texture)=> {
                    this.isImageLoaded = true;   
                    
                    if(!this.isVideoLoaded) {
                        this.material.uniforms.texture1.value = texture;
                    }
                } 
            });
        }

        if(this.video && !isSmartphone) {
            this.video.crossOrigin = 'anonymous';
                                 
            const _play = () => {
                this.isVideoLoaded = true;
                this.video.removeEventListener('play', _play);
                this.material.uniforms.texture1.value = new VideoTexture(this.video);
            }
            this.video.addEventListener('play', _play);
            //this.video.play();
        }    

        if (this.dom.dataset.mask) {
            TexturesController.load({
                src: this.dom.dataset.mask,
                material: this.material.uniforms.texture1,
                attribute: "value"
            });
        }
    }

    calculateResolution() {
        const width = this.size.x;
        const height = this.size.y;

        const planeAspect = this.mesh.scale.y / this.mesh.scale.x;
        let a1;
        let a2;

        if (height / width > planeAspect) {
            const h = (width / height) * planeAspect;
            // Canvas more horizontal than image
            a1 = 1;
            a2 = h;
        } else {
            // Canvas more vertical than image
            a1 = height / width / planeAspect;
            a2 = 1;
        }

        return {
            x: a1,
            y: a2
        };
    }

    resize() {
        const { x, y, width, height } = this.dom.getBoundingClientRect();
        
        if(!this.opts.hasFreePosition) {
            const position = this.domPositionTo3D(x, y);
            this.position.x =
                this.pos.x = position.x + width * .5;
            this.position.y =
                this.pos.y = position.y - height * .5;
        }

        super.resize(width, height);

        this.material.uniforms.resolution.value = this.calculateResolution();
    }
}
